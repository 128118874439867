import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, Typography } from "@mui/material";

export default function ConfirmDialog({
    open,
    output,
    onClose
}: {
    open: boolean,
    output: (confirm: boolean) => void,
    onClose: () => void
}) {

    const handleCancel = () => {
        onClose();
    };

    const handleConfirm = () => {
        output(true);
        onClose();
    }

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Are you done with this bill?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Thank you for using our app to split your bill
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>No</Button>
                <Button onClick={handleConfirm} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}