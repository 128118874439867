import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { splitMyBillAPI } from './queries';
import reducer from './user.slice';

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [splitMyBillAPI.reducerPath]: splitMyBillAPI.reducer,

    // User reducer
    user: reducer
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(splitMyBillAPI.middleware),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)