import { createSlice } from "@reduxjs/toolkit";

export interface UserStore {
    userId: string | null;
    userName: string | null;
    status: 'unknown' | 'signedIntoClerk' | 'guestUser';
    clerkUser?: {
        id: string,
        email: string
    }
    error: string | null | undefined;
}

const initialState: UserStore = {
    userId: null,
    userName: null,
    status: 'unknown',
    clerkUser: undefined,
    error: undefined
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser(state, action: {payload: UserStore}) {
            return action.payload;
        },
        setAuthError(state, action) {
            state.error = action.payload;
        },
        setAuthStatus(state, action) {
            state.status = action.payload;
        }
    }
})

export const {
    setAuthError, 
    setAuthStatus, 
    setUser
} = userSlice.actions;

export default userSlice.reducer;