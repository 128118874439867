import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ClerkProvider } from '@clerk/clerk-react';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <ClerkProvider 
      publishableKey={process.env.REACT_APP_CLERK_API_KEY ?? ''} 
      afterSignOutUrl="/" 
      appearance={{
        elements: {
          rootBox: {
              fontFamily: 'sans-serif'
          }
        }
      }}
    >
        <App />
    </ClerkProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
