import React, { useEffect, useRef, useState } from 'react';
import MyButton from '../components/Button';
import Webcam from "react-webcam";
import { useNavigate, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { useUploadFileMutation } from '../api/queries';
import { Backdrop, CircularProgress } from '@mui/material';

function CaptureReceiptPage() {
    // Fetch user state from local storage
    const userId = useSelector((state: any) => state.user.userId);

    // const inputRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();

    // Get the pool ID from URL params
    const [searchParams] = useSearchParams();
    const isGalleryImage = searchParams.get("isGalleryImage")

    const webcamRef = useRef<Webcam | null>(null);
    const [imageData, setImageData] = useState<string | null>(null);
    const [isCameraOpen] = useState(isGalleryImage == null);

    const [uploadFile, { isLoading }] = useUploadFileMutation();

    useEffect(() => {
        if (isGalleryImage) {
            showGalleryImage();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleAnalyseReceiptClick = async () => {
        if (!imageData) return;
        const result = await uploadFile({
            imageData,
            userId
        }).unwrap();

        if (!result) console.log('Error while uploading file');
        navigate(`/receipt-processed?poolId=${result.id}`);
    };

    const capture = () => {
        const imageSrc = webcamRef.current?.getScreenshot();
        setImageData(imageSrc || null);
    };

    const showGalleryImage = () => {
        // Retrieve file data from sessionStorage
        const imageSrc = sessionStorage.getItem('fileContent');
        setImageData(imageSrc || null);
    }

    const videoConstraints = {
        facingMode: { exact: "environment" }
    };

    return (
        <>
            <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            

            {isCameraOpen && !imageData && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height={300}
                    sx={{ width: '80%', margin: 'auto', paddingTop: '40%' }}
                >
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                        style={{ width: '100%' }}
                    />
                </Box>
            )}

            {imageData && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height={300}
                    sx={{ width: '80%', margin: 'auto', paddingTop: '40%' }}
                >
                    <img
                        src={imageData}
                        alt="Captured"
                        style={{ width: '100%' }}
                    />
                </Box>
            )}

            <Box display="flex" justifyContent="center" alignItems="center">

                {!imageData && isCameraOpen &&
                    <MyButton
                        onClick={capture}
                        buttonText="Capture photo"
                        top="80%"
                        left="50%"
                        transform="translate(-50%, -50%) scale(1.25)"
                        width='200px'
                    />
                }

                {imageData &&
                    <MyButton
                        onClick={handleAnalyseReceiptClick}
                        buttonText="Analyse Receipt"
                        top="80%"
                        left="50%"
                        transform="translate(-50%, -50%) scale(1.25)"
                        width='200px'
                    />
                }
            </Box>
        </>
    );
}

export default CaptureReceiptPage;