import React from 'react';
import Button from '@mui/material/Button';

interface CaptureButtonProps {
    onClick: () => void;
    buttonText: string;
    top: string;
    left: string;
    transform: string;
    width: string;
}

const CustomButton: React.FC<CaptureButtonProps> = ({ onClick, buttonText, top, left, transform, width }) => {
    return (
        <Button 
            variant="contained" 
            sx={{ 
                width: width,
                position: 'absolute', 
                top: top, 
                left: left, 
                transform: transform, 
            }} 
            onClick={onClick}
        >
            {buttonText}
        </Button>
    );
}

export default CustomButton;