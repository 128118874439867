import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ButtonGroup, InputAdornment, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

export default function TipInputDialog({
    billTotal,
    open,
    output,
    onClose
}: {
    billTotal: number,
    open: boolean,
    output: (value: number, percentage: number, lastTouched: 'value' | 'percentage') => void,
    onClose: () => void
}) {
    const [value, setValue] = useState<number>(0);
    const [percentage, setPercentage] = useState<number>(10);
    const [lastTouched, setLastTouched] = useState<'value' | 'percentage'>('percentage');

    useEffect(() => {
        if (billTotal) {
            const tip = Math.floor(billTotal * (percentage / 100))
            setValue(tip);
        }
    }, [billTotal])

    const handleValueChange = (newValue: string) => {
        let isnum = /^[0-9]+(\.?[0-9]{0,2})?$/.test(newValue);
        if (isnum) {
            const parsedValue = Number(newValue);
            setValue(parsedValue);
            setLastTouched('value');
        }
    }

    const handlePercentageChange = (newPercentage: number) => {
        setPercentage(newPercentage);
        const tip = billTotal * (newPercentage / 100);
        setValue(Math.round((tip + Number.EPSILON) * 100) / 100)
        setLastTouched('percentage');
    }

    const handleCancel = () => {
        onClose();
    };

    const handleConfirm = () => {
        output(value, percentage, lastTouched);
        onClose();
    }

    return (
        <React.Fragment>
            <Dialog
                // fullWidth={fullWidth}
                // maxWidth={maxWidth}
                open={open}
                onClose={handleCancel}
            >
                <DialogTitle>Add a tip</DialogTitle>
                <DialogContent>
                    <DialogContentText
                        margin={2}>
                        You can set the exact amount or pick a percentage
                    </DialogContentText>

                    <Box
                        noValidate
                        component="form"
                        sx={{
                            p: 1.5,
                            display: 'flex',
                            flexDirection: 'column',
                            m: 'auto',
                        }}
                    >

                        <TextField
                            label="Tip"
                            id="outlined-start-adornment"
                            value={value}
                            type='number'
                            slotProps={{
                                input: {
                                    startAdornment: <InputAdornment position="start">R</InputAdornment>,
                                },
                            }}
                            onChange={(event) => handleValueChange(event.target.value)}
                        />
                        <ButtonGroup size="large" aria-label="Tip percentage button group">
                            <Button key="5" onClick={() => handlePercentageChange(5)}>5%</Button>
                            <Button key="10" onClick={() => handlePercentageChange(10)}>10%</Button>
                            <Button key="15" onClick={() => handlePercentageChange(15)}>15%</Button>
                            <Button key="20" onClick={() => handlePercentageChange(20)}>20%</Button>
                        </ButtonGroup>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleConfirm}>OK</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
