import React from 'react';
import { Container, ThemeProvider, Box } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './api/store';
import HomePage from './pages/HomePage';
import CaptureReceiptPage from './pages/CaptureReceiptPage';
import ReceiptProcessed from './pages/ReceiptProcessed';
import AuthGuard from './guards/authGuard';
import LoginPage from './pages/Login';
import { ToastContainer } from 'react-toastify';
import themes from './styles/theme';
import bgImage from './assets/BackGround.jpeg';
import AppBarComponent from './components/AppBarComponent';
import AdsComponent from './components/AdsComponent';
import './styles.css';
import 'react-toastify/dist/ReactToastify.css'

const injectGlobalStyles = (theme) => {
  const style = document.createElement('style');
  style.innerHTML = `
    html, body {
      font-family: ${theme.typography.fontFamily};
    }
  `;
  document.head.appendChild(style);
};

injectGlobalStyles(themes.dark);

function App() {
  return (
    <ThemeProvider theme={themes.dark}>
      <ToastContainer 
        theme='colored'
        position="top-center"
        hideProgressBar={true}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <AppBarComponent />
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', height: '94vh', overflow: 'auto' }}>
          <Container
            sx={{
              flex: 1,
              width: '100%',
              padding: 0,
              margin: 0,
              backgroundImage: `url(${bgImage})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover'
            }}
          >
            <Provider store={store}>
              <BrowserRouter>
                <AuthGuard>
                  <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/" element={<HomePage />} />
                    <Route path="/capture-receipt" element={<CaptureReceiptPage />} />
                    <Route path="/receipt-processed" element={<ReceiptProcessed />} />
                  </Routes>
                </AuthGuard>
              </BrowserRouter>
            </Provider>
          </Container>
        </Box>
        <Box sx={{ height: '6vh' }}>
          <AdsComponent />
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;