import { Typography, Box, ButtonBase, styled, Backdrop, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';

function HomePage() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const { isSignedIn, user } = useUser();

    const handleTakePhotoClick = () => {
        navigate('/capture-receipt');
    }; 

    const handleCaptureReceiptClick = () => {
        // Create an input element
        var inputElement = document.createElement('input');
        // Hide element and append to body (required to run on iOS safari)
        inputElement.style.display = 'none';
        document.body.appendChild(inputElement);
        // Set its type to file
        inputElement.type = 'file';
        // Set accept to the file types you want the user to select.
        inputElement.accept = 'image/*';
        // Accept multiple files
        inputElement.multiple = false;
        // set onchange event to call callback when user has selected file
        inputElement.addEventListener('change', evt => {
            // get the file input element
            const eventTarget = evt.target as HTMLInputElement;
            // convert the files to a pfo array
            const plainFileObjects = eventTarget.files ? Array.from(eventTarget.files) : [];
            // check if the user selected anything
            if (plainFileObjects.length > 0) {
                // grab the first file
                const file = plainFileObjects[0];
                const reader = new FileReader();
                // read the file
                reader.onload = () => {
                    // The file content as a base64 encoded string
                    const fileContent = reader.result as string;
                     // Store the file data in sessionStorage
                    sessionStorage.setItem('fileContent', fileContent);
                    // navigate to the next page
                    navigate('/capture-receipt?isGalleryImage=true');
                };
                // file reader error listener
                reader.onerror = () => {
                    console.error("Failed to read the file");
                };    
                // Read the file as a Data URL (base64 encoded string)
                reader.readAsDataURL(file);
            }            
            // remove input element
            document.body.removeChild(eventTarget);
        });
        // dispatch a click event to open the file dialog
        inputElement.dispatchEvent(new MouseEvent('click'));
    }  

    const images = [
        {
            url: `url(${process.env.PUBLIC_URL + '/UploadPhotoImage.png'})`,
            title: 'Snap A Pic',
            onSelect: handleTakePhotoClick,
        },
        {
            url: `url(${process.env.PUBLIC_URL + '/CaptureReceiptImage.png'})`,
            title: 'Gallery',
            onSelect: handleCaptureReceiptClick,
        }
    ];

    const ImageButton = styled(ButtonBase)(({ theme }) => ({
        position: 'relative',
        height: 200,
        [theme.breakpoints.down('sm')]: {
            width: '100% !important', // Overrides inline-style
            height: 280,
        },
        '&:hover, &.Mui-focusVisible': {
            zIndex: 1,
            '& .MuiImageBackdrop-root': {
                opacity: 0.15,
            },
            '& .MuiImageMarked-root': {
                opacity: 0,
            },
            '& .MuiTypography-root': {
                border: '4px solid currentColor',
            },
        },
    }));

    const ImageSrc = styled('span')({
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
    });

    const Image = styled('span')(({ theme }) => ({
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
    }));

    const ImageBackdrop = styled('span')(({ theme }) => ({
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
        transition: theme.transitions.create('opacity'),
    }));

    const ImageMarked = styled('span')(({ theme }) => ({
        height: 3,
        width: 18,
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
    }));


    useEffect(() => {
        if (isSignedIn !== undefined) {
            setLoading(false);
        }
    }, [isSignedIn]);

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>            
            <Box sx={{p:2}}>
                <Typography variant="h6" color={'white'} ml={2}>
                    Welcome {isSignedIn && user ? user.firstName : ''}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', minWidth: 300, width: '100%' }}>
                {images.map((image) => (
                    <ImageButton
                        focusRipple
                        key={image.title}
                        onClick={image.onSelect}
                    >
                        <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
                        <ImageBackdrop className="MuiImageBackdrop-root" />
                        <Image>
                            <Typography
                                component="span"
                                variant="subtitle1"
                                color="inherit"
                                sx={{
                                    position: 'relative',
                                    p: 4,
                                    pt: 2,
                                    pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                                }}
                            >
                                {image.title}
                                <ImageMarked className="MuiImageMarked-root" />
                            </Typography>
                        </Image>
                    </ImageButton>
                ))}
            </Box>
        </>
    );
}

export default HomePage;